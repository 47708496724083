<script>
    import { onMount } from "svelte";
    import MovieTrailerPopup from "../movies/MovieTrailerPopup.svelte";
    import { youtube_parser } from "../../utils/utils.js";

    export let movie;

    let movieId;

    onMount(() => {
        movieId = getMovieUrl(movie);
    })

    const getMovieUrl = (movie) => {
        const trailers = movie['trailers'];
        if (trailers != null && trailers.length > 0) {
            const trailerFiles = trailers[0]['trailer_files'];
            if (trailerFiles != null && trailerFiles.length > 0) {
                const url = trailerFiles[0]['url'];
                return youtube_parser(url);
            }
        }
        return null;
    };
</script>

<div class="w-full h-full flex justify-end">
    <MovieTrailerPopup {movie} {movieId} trailerClasses={"w-full h-full"} />  
</div>