module.exports = {
  content: [
    './app/views/**/*.html.erb',
    './app/helpers/**/*.rb',
    './app/javascript/**/*.js',
    './app/javascript/**/*.svelte',
  ],
  theme: {
    screens: {
      'xs': '280px',
      'sm': '642px',
      'md': '830px',
      'lg': '1024px',
      'xl': '1280px',
      '2xl': '1536px',
      '3xl': '1792px',
    },
    colors: {
      'orange': '#eb6135',
      'red': '#d20000',
      'white': '#ffffff',
      'gray-light': '#979797',
      'black': '#000000',
    },
    fontFamily: {
      sans: ['brandon-grotesque', 'sans-serif'],
    },
    extend: {
      backgroundImage: {
        'gruvi-poster': "url('https://assets.gruvi.tv/gruvimovies/movie_poster_placeholder_black.jpg')",
        'gruvi-home-header': "url('https://assets.gruvi.tv/gruvimovies/home_header_twowaves.jpg')",
        'gruvi-footer-1': "url('https://assets.gruvi.tv/gruvimovies/gruvi_movies_footer_1.jpg')",
        'gruvi-footer-2': "url('https://assets.gruvi.tv/gruvimovies/gruvi_movies_footer_2.jpg')",
      },
      animation: {
        fadeIn: 'fadeIn 0.5s ease-in-out'
      },
      keyframes: {
        fadeIn: {
          '0%': { opacity: 0 },
          '100%': { opacity: 1 },
        }
      }
    }
  }
}
