<script>
    import FaFacebook from 'svelte-icons/fa/FaFacebook.svelte'
    import FaTwitter from 'svelte-icons/fa/FaTwitter.svelte'
    import { format } from 'date-fns'

    const copyrightText = `Copyright ${format(new Date(), 'y')} gruvi Movies. All Rights Reserved`;
    const gruvi_logo = "https://gruvi-houston-assets.s3.eu-central-1.amazonaws.com/gruvi-logo-white.svg";
    const gruvi_facebook = "https://www.facebook.com/TeamGruvi";
    const gruvi_twitter = "https://twitter.com/projectgruvi";

    const footerList = [
        {
            label: 'impressum',
            link: '/impressum'
        },
        {
            label: 'agb',
            link: '/agb'
        },
    ]
    
    const socialList = [
        {
            icon: FaFacebook,
            link: gruvi_facebook
        },
        {
            icon: FaTwitter,
            link: gruvi_twitter
        }
    ]

    const socialMediaClick = (link) => {
        switch (link) {
            case socialList[0].link:
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'autoEvent',
                    'category': 'Social Media Interactions',
                    'action': 'Click  to Facebook'
                });
                break;
            case socialList[1].link:
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'autoEvent',
                    'category': 'Social Media Interactions',
                    'action': 'Click  to Twitter'
                });
                break;
            default:
                break;
        }
    }
</script>

<div class="w-full pb-5 bg-gruvi-footer-2 bg-cover bg-center flex flex-col items-center">
    <div class="w-full flex flex-row justify-between items-end">
        <ul class="flex flex-col pl-5 md:pl-6 lg:pl-7 xl:pl-8">
            <li>
                <a href="https://www.gruvi.tv/">
                    <img src={gruvi_logo} alt="gruvi-logo" class="w-16 sm:w-20 md:w-24 lg:w-28 xl:w-32 2xl:w-36 my-2 md:my-3 lg:my-4 xl:my-5" />
                </a>
            </li>
            {#each footerList as item}
                <li class="sm:pb-1 md:pb-2 xl:pb-3">
                    <a href={item.link}>
                        <div class="font-light text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-3xl text-white uppercase hover:underline">{item.label}</div>
                    </a>
                </li>
            {/each}
        </ul>
        <div class="flex flex-col items-end pr-5 md:pr-6 lg:pr-7 xl:pr-8 md:pb-2 lg:pb-3 xl:pb-4 2xl:pb-5">
            <div class="mb-1 md:mb-2 xl:mb-3 font-light text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-3xl text-white uppercase">Gruvi on social media</div>
            <ul class="m-0 flex flex-row">
                {#each socialList as item}
                    <li class="px-1 w-8 xl:w-12 2xl:w-16 text-white hover:text-black">
                        <a href={item.link} target="_blank" on:click={() => socialMediaClick(item.link)}>
                            <svelte:component this={item.icon} />
                        </a>
                    </li>
                {/each}
            </ul>
        </div>
    </div>
    <div class="my-0.5 flex-row justify-center w-full text-light text-center text-white text-2xs md:text-sm lg:text-base xl:text-lg 2xl:text-xl 3xl:text-2xl">
        <a class="hover:underline" href="/kinotickets-online">Kinotickets Online</a>
        <a class="ml-4 hover:underline" href="/kinotickets-bestellen">Kinotickets Bestellen</a>
        <a class="ml-4 hover:underline" href="/kinokarten-reservieren">Kinokarten Reservieren</a>
    </div>
    <p class="text-2xs mt-2 md:text-sm text-white">{copyrightText}</p>
</div>
