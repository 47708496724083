<script>
    import { onMount } from 'svelte';
    import { fetchUrl, getGenresString } from '../../utils/utils'; 
    import ShowtimesTrailer from './ShowtimesTrailer.svelte';
    import ShowtimesMovieInfo from './ShowtimesMovieInfo.svelte';

    export let movie;

    onMount(()=>{
        if (movie) {
            fetchUrl({
                url: '/movies/genres?lang=en',
            }).then(data => {
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'autoEvent',
                    'category': 'Website Interactions',
                    'action': 'Film Page View',
                    'film_name': movie["original_title"],	                                                                        // Film Name
                    'film_id': movie["id"],						                                                                    // Unique Film ID
                    'genre': getGenresString(data["genres"], movie["genres"]),	                                                    // Film Genre
                    'production_companies': movie["production_companies"] != null ? movie["production_companies"].join() : '',      // Production Companies
                });
            });
        }
    });
</script>

<div class="relative flex flex-col items-center px-12 sm:px-20 2xl:px-32 py-4 md:py-8 lg:py-12" itemscope itemtype="http://schema.org/Movie">
<div class="text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-center font-bold uppercase" itemprop="alternateName">{movie ? movie['title'] : ''}</div>
<div class="md:mt-2 text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-light">IMDb: {movie['ratings'] && movie['ratings']['tmdb'] ? movie['ratings']['tmdb']['value'].toFixed(1) : 'N/A'}</div>
<div class="w-full sm:w-50vw md:w-auto flex flex-col md:flex-row md:justify-end md:pt-10 md:px-12 items-center md:items-start">
    <div class="w-full w-60vw sm:w-50vw lg:w-40vw 2xl:w-30vw h-40vw sm:h-64 md:h-25vw 2xl:h-20vw p-2 md:pr-10">
        <ShowtimesTrailer {movie} />
    </div>
    <div class="w-full md:w-1/2 flex flex-col items-center">
        <ShowtimesMovieInfo {movie} />
    </div>
</div>
<div class="pt-4 md:pt-8 lg:pt-12 text-xl md:text-2xl lg:text-3xl 2xl:text-4xl text-center font-bold uppercase">
    Tickets kaufen für {movie ? movie['title'] : ''}
</div>
<div class="hidden md:block absolute top-0 left-0 ml-3 mt-1 md:ml-8 md:mt-4">
    <a class="flex items-center fill-orange hover:cursor-pointer" href="/">
    <svg class="w-6 md:w-4 xl:w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z"/></svg>
    <span class="pl-2 text-orange text-sm lg:text-base xl:text-lg 2xl:text-xl invisible md:visible hover:underline">Startseite<span>
    </a>
</div>
</div>

