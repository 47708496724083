<script>
  import { createEventDispatcher } from 'svelte';

  export let name;
  export let value;
  export let placeholder = "";
  export let icon = "";
  export let classes = "";
  export let inputClasses = "";
  export let iconClasses = "";
  export let inputField;
  
  const dispatch = createEventDispatcher();

  const onInput = (event) => {
    dispatch('input', event.target.value);
  }

  const onChange = (event) => {
    dispatch('change', event.target.value);
  }

  const onFocus = (event) => {
    if ( event.target.value === "0" ) {
      value = "";
    }
  }
</script>


<div class="relative {classes}">
  <input
    bind:this={inputField} 
    class={inputClasses}
    id={name}
    {name}
    {placeholder}
    bind:value={value}
    type="text"
    on:focus={onFocus}
    on:input={onInput}
    on:change={onChange}
  />
  {#if icon != ""}
    <div class="absolute right-1 top-0.5 w-5 md:w-8 {iconClasses}">
      <slot></slot>
    </div>
  {/if}
</div>

<style>
  input:focus::placeholder {
    color: transparent;
  }
</style>
