<script>
    import { afterUpdate, createEventDispatcher } from 'svelte';
    import { currentShowtimesCinemas } from "../../stores/showtimes_store";
    import { GOOGLE_MAPS_STYLE } from '../../utils/googleMapsStyles';

    export let latitude;
    export let longitude;

    const dispatch = createEventDispatcher();

    let map = null;
    let markers = [];

    const customMarker = "https://gruvi-temp.s3.amazonaws.com/images/marker2_64x100.png";

    const mapOptions = {
        zoom: 15,
        mapTypeControl: true,
        panControl: false,
        zoomControl: true,
        zoomControlOptions: {
            style: google.maps.ZoomControlStyle.DEFAULT
        },
        scaleControl: false,
        styles: GOOGLE_MAPS_STYLE,
        rotateControl: false,
        overviewMapControl: false
    };

    const clearMarkers = () => {
        markers.forEach(marker => {
            marker.setMap(null);
        });
        markers = [];
    }

    export const setMapCenter = (lat, lon) => {
        map.setCenter({lat: Number(lat), lng: Number(lon)});
        map.setZoom(16);
    }

    const drawCinemasOnMap = () => {
        clearMarkers();
        if($currentShowtimesCinemas.length > 0){
            const infowindow = new google.maps.InfoWindow();
            $currentShowtimesCinemas.forEach(cinemaShowtimes => {
                const marker = new google.maps.Marker({
                    position: new google.maps.LatLng(cinemaShowtimes["location"]["lat"], cinemaShowtimes["location"]["lon"]),
                    title: cinemaShowtimes["name"],
                    map: map,
                    icon: {
                        url: customMarker,
                        scaledSize: new google.maps.Size(32, 50)
                    }
                });
                marker.setMap(map);
                markers.push(marker);

                google.maps.event.addListener(marker, 'click', function () {
                    dispatch('markerClick', {
                        text: cinemaShowtimes["id"],
                    });
                    map.setCenter({lat: Number(cinemaShowtimes["location"]["lat"]), lng: Number(cinemaShowtimes["location"]["lon"])});
                    map.setZoom(16);
                });

                google.maps.event.addListener(marker, 'mouseover', function () {
                    infowindow.setContent('<h3>'+cinemaShowtimes["name"]+'</h3><p>'+cinemaShowtimes["location"]["address"]["display_text"]+'</p>');
                    infowindow.open(map, marker);
                });

                google.maps.event.addListener(marker, 'mouseout', function () {
                    infowindow.close(map, marker);
                });
            });
        }
    }

    afterUpdate(()=>{
        if (!map) map = new google.maps.Map(document.getElementById("map"), mapOptions);
        
        drawCinemasOnMap();
        
        map.setCenter({lat: Number(latitude), lng: Number(longitude)});
        map.setZoom(16);
        
        const listener = google.maps.event.addListener(map, "idle", function () {
            if (map.getZoom() > 16) map.setZoom(16);
            google.maps.event.removeListener(listener);
        });
    });
</script>

<div id="map" class="w-full h-56 sm:h-full">

</div>