<script>
    import IoIosMenu from 'svelte-icons/io/IoIosMenu.svelte';
    import MdClose from 'svelte-icons/md/MdClose.svelte';
    import { fly } from 'svelte/transition';

    export let transparent;

    let expanded = false;
    let windowWidth;
    const gruvi_movies_logo_simple_black = "https://assets.gruvi.tv/gruvimovies/gruvi_movies_logo_simple_black.svg";
    const navList = [
        {
            link: '/',
            label: 'Startseite',
        },
        {
            link: '/impressum',
            label: 'Impressum',
        },
        {
            link: '/agb',
            label: 'Agb',
        },
    ];

    const openMenu = () => {
        expanded = true;
    }

    const closeMenu = () => {
        expanded = false;
    }
</script>

<svelte:window bind:innerWidth={windowWidth}/>

<div class="flex flex-col">
    <nav class="w-screen max-h-screen flex flex-col z-10">
        <div class="w-full px-4 xl:px-6 md:py-3 xl:py-5  {transparent ? 'absolute' : 'bg-gruvi-footer-1 bg-cover bg-center'} flex flex-row-reverse items-center justify-between">
            {#if expanded}
                <div class="z-20 md:hidden m-2 text-white w-8" on:click={closeMenu}>
                    <MdClose />
                </div>
            {:else}
                <div class="z-20 md:hidden m-2 text-white w-8" on:click={openMenu}>
                    <IoIosMenu />
                </div>
            {/if}
            <ul class="hidden md:flex flex-wrap">
                {#each navList as item}
                    <li class="mr-4"><a href={item.link} class="text-2xl xl:text-3xl 2xl:text-4xl text-black uppercase hover:underline">{item.label}</a></li>
                {/each}
            </ul>
            <a href="/">
                <img src={gruvi_movies_logo_simple_black} alt="gruvi-movies-logo" class="w-16 sm:w-20 md:w-24 lg:w-28 xl:w-32 2xl:w-36" />
            </a>
        </div>
        {#if expanded}
            <ul 
                class="w-full h-64 absolute {transparent ? 'bg-black bg-opacity-75 md:bg-opacity-0' : 'bg-orange'} md:pt-12 flex flex-col justify-evenly items-center md:hidden"
                in:fly="{{ y: -200, duration: 500 }}" 
                out:fly="{{ y: -200, duration: 500 }}" 
            >
                {#each navList as item}
                    <li><a href={item.link} class="text-2xl text-white uppercase">{item.label}</a></li>
                {/each}
            </ul>
        {/if}
    </nav>
</div>
