<script>
    import { addHours, compareAsc, parseISO } from 'date-fns';
    import { onMount } from 'svelte';
    import { fade } from 'svelte/transition';
    import { setCookie, getCookie, setGTM } from '../utils/utils';
  
    let showCookieNotice = getCookie("cookieNotice") !== "accepted" && (getCookie('cookieTimeout') == undefined || compareAsc(new Date(), parseISO(getCookie('cookieTimeout'))) > 0);
  
    if ( window.navigator.userAgent.match(/FBAN|FB_IAB|Twitter for iPhone|TwitterAndroid|Instagram|Snapchat|musical_ly/i)) {
        setGTM();
        showCookieNotice = false;
    }
    
    const handleAccept = () => {
        showCookieNotice = false;
        setCookie("cookieNotice", "accepted");
        setGTM();
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': 'autoEvent',
            'category': 'Cookies Notice',
            'action': 'Cookies Accepted',
            'cookies_accepted': true,
        });
    }
  
    const handleReject = () => {
        showCookieNotice = false;
        setCookie("cookieNotice", "rejected");
        setCookie("cookieTimeout", addHours(new Date(), 24));
    }

    onMount(()=>{
        if (getCookie("cookieNotice") == "accepted") {
            setGTM();
        }
    });
</script>
  
{#if showCookieNotice}
    <div
      class="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center z-10000 bg-black bg-opacity-60"
      in:fade
      out:fade
    >
      <div class="max-w-xl min-w-35 p-10 flex flex-col justify-center bg-white text-black text-base text-center border-0 rounded-2xl overflow-hidden">
          <div class="mb-5">{"This website uses cookies to track if you've visited it, with the intent to improve the use of advertisement target at you. If you accept these cookies, click on 'I Accept', otherwise click 'I do not accept' to prevent these cookies from tracking you now and in the future." }</div>    
          <div class="w-full flex flex-row justify-center">
            <div class="py-2 px-4 mx-4 bg-white border-2 border-orange rounded-3xl text-orange hover:cursor-pointer" on:click={handleReject}>{"I Do Not Accept" }</div>
            <div class="py-2 px-4 mx-4 bg-orange border-2 border-orange rounded-3xl text-white hover:cursor-pointer" on:click={handleAccept}>{"I Accept"}</div>
          </div>
      </div>
    </div>
{/if}