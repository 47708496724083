// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

import WebpackerSvelte from "webpacker-svelte";

import CookieNotice from "../components/CookieNotice.svelte";
import NavBar from "../components/shared/NavBar.svelte";
import Footer from "../components/shared/Footer.svelte";
import HomeInfo from "../components/movies/HomeInfo.svelte";
import MovieGridContainer from "../components/movies/MovieGridContainer.svelte";
import MovieSearch from "../components/movies/MovieSearch.svelte";
import ShowtimesCalendar from "../components/showtimes/ShowtimesCalendar.svelte";
import ShowtimesCinemas from "../components/showtimes/ShowtimesCinemas.svelte";
import ShowtimesPage from "../components/showtimes/ShowtimesPage.svelte";
import ShowtimesPlaces from "../components/showtimes/ShowtimesPlaces.svelte";

WebpackerSvelte.setup({
    CookieNotice, 
    HomeInfo,
    NavBar,
    Footer,
    MovieGridContainer, 
    MovieSearch,  
    ShowtimesCalendar, 
    ShowtimesPlaces,
    ShowtimesCinemas, 
    ShowtimesPage, 
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
