<script>
    import { currentShowtimesCinemas, isLoading } from "../../stores/showtimes_store";
    import { theme } from "../../../../config/tailwind.config.js";
    import ShowtimesInfo from './ShowtimesInfo.svelte';
    import ShowtimesMap from './ShowtimesMap.svelte';
    import MdPinDrop from 'svelte-icons/md/MdPinDrop.svelte';
    import { fetchUrl, getGenresString } from "../../utils/utils.js";
    import { parseISO, format } from 'date-fns';
    import { de } from 'date-fns/locale';
    import { Shadow } from 'svelte-loading-spinners';

    export let movie;

    let mapIndex = null;
    let windowWidth;
    let map;

    const minWindowScreen = Number.parseInt(theme.screens.sm.slice(0, theme.screens.sm.length - 2));

    const selectShowtimesMap = async (cinemaShowtimes) => {
        mapIndex = cinemaShowtimes['id'];
        if (windowWidth < minWindowScreen) {
            await new Promise(r => setTimeout(r, 50));
            const el = document.getElementById('map');
            if (!el) return;
            el.scrollIntoView(false, {
                behavior: 'smooth',
            });
        }

        map.setMapCenter(cinemaShowtimes["location"]["lat"], cinemaShowtimes["location"]["lon"]);
    }

    const handleMarkerClick = (event) => {
        const el = document.getElementById('cinemaShowtimes_' + event.detail.text);
        if (!el) return;
        el.scrollIntoView({
            behavior: 'smooth',
        });
    }

    const handleShowtimeExit = (event, cinemaShowtime) => {
        fetchUrl({
            url: '/movies/genres?lang=en',
        }).then(data => {
            const datetime = parseISO(event.detail.showtime["start_at"]);
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'autoEvent',
                'category': 'Showtimes Interactions',
                'action': 'Showtime Exit',
                'film_name': movie["original_title"],                                                                           // Film Name
                'film_id': movie["id"],						                                                                    // Unique Film ID
                'genre': getGenresString(data["genres"], movie["genres"]),				                                        // Film Genre
                'location': cinemaShowtime["location"]["address"]["city"],				                                        // City
                'address': cinemaShowtime["location"]["address"]["display_text"],		                                        // Address
                'cinema_name': cinemaShowtime["name"],			                                                                // Cinema Name
                'showtime_time': format(datetime, 'H:m', { locale: de }),		                                                // Showtime Time
                'showtime_date': format(datetime, 'yyyy-MM-dd', { locale: de }),                                                // Showtime Date
                'production_companies': movie["production_companies"] != null ? movie["production_companies"].join() : '',      // Production Companies

            });
        });
    }
</script>

<svelte:window bind:innerWidth={windowWidth}/>

<div class="w-full flex flex-col items-center">
    {#if !$isLoading}
        {#if $currentShowtimesCinemas.length > 0}
            <div class="w-full h-150 flex flex-col sm:flex-row">
                <div class="sm:w-3/5 h-full overflow-scroll scroll-smooth">
                    {#each $currentShowtimesCinemas as cinemaShowtimes}
                        <div id={'cinemaShowtimes_' + cinemaShowtimes['id']} class="w-full flex flex-col shadow-inner">
                            <div class="w-full px-10 py-2 md:py-3 xl:py-4 flex flex-col">
                                <div class="w-full flex flex-row justify-between">
                                    <div class="w-4/5 flex flex-col items-start">
                                        <span class="text-sm md:text-lg 2xl:text-3xl text-black font-bold uppercase">{cinemaShowtimes["name"]}</span>
                                        <div class="flex flex-row items-center mb-2">
                                            <div class="w-4 h-4 2xl:w-6 2xl:h-6">
                                                <MdPinDrop />
                                            </div>
                                            <span class="text-xs md:text-base 2xl:text-2xl">{cinemaShowtimes["location"]["address"]["display_text"]}</span>
                                        </div>
                                    </div>
                                    <div class="transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-100 w-8 h-8 p-0.5 md:w-10 md:h-10 xl:w-14 xl:h-14 md:p-1 xl:p-2 ml-2 text-white bg-orange hover:bg-black hover:cursor-pointer" on:click|preventDefault={() => selectShowtimesMap(cinemaShowtimes)}>
                                        <MdPinDrop />
                                    </div>
                                </div>
                                <ShowtimesInfo showtimes={cinemaShowtimes["showtimes"]} on:showtimeExit={(e) => handleShowtimeExit(e, cinemaShowtimes)} />
                            </div>
                            {#if mapIndex === cinemaShowtimes["id"] && windowWidth < minWindowScreen}
                                <ShowtimesMap 
                                    latitude={cinemaShowtimes["location"]["lat"]} 
                                    longitude={cinemaShowtimes["location"]["lon"]}
                                    bind:this={map}
                                />
                            {/if}
                        </div>
                    {/each}
                </div>
                {#if windowWidth >= minWindowScreen}
                    <div class="sm:w-2/5 h-full">
                        <ShowtimesMap 
                            latitude={$currentShowtimesCinemas[0]["location"]["lat"]}
                            longitude={$currentShowtimesCinemas[0]["location"]["lon"]}
                            on:markerClick={handleMarkerClick}
                            bind:this={map}
                        />
                    </div>
                {/if}    
            </div>
        {:else}
            <p class="pb-4 pt-4 md:pb-8 md:pt-8 text-black text-center text-sm md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-light">Keine Spielzeiten verfügbar</p>
        {/if}
    {:else}
        <div class="py-12">
            <Shadow size="32" color="#525252" unit="px" duration="1s"></Shadow>
        </div>
    {/if}
</div>
