<script>
    import { getContext } from "svelte";
    import MovieTrailerPopup from './MovieTrailerPopup.svelte';
    import { youtube_parser, fetchUrl, getGenresString } from "../../utils/utils.js";
    import { theme } from "../../../../config/tailwind.config.js";

    export let movie;
    export let upcoming;

    const { open } = getContext('simple-modal');

    let hovered = false;
    let windowWidth;
    const minOptionsScreenWidth = Number.parseInt(theme.screens.md.slice(0, theme.screens.sm.length - 2));
    const play_movie_poster = 'https://assets.gruvi.tv/gruvimovies/play_movie_poster.svg';

    const modalStyle = {
        closeButton: false,
        styleWindow: { 
            width: '80%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'none'
        },
    }

    const showPopup = (movie) => {
        const url = getMovieUrl(movie);
		open(MovieTrailerPopup, { movie: movie, movieId: url }, modalStyle);
	};

    const getMovieUrl = (movie) => {
        const trailers = movie['trailers'];
        if (trailers != null && trailers.length > 0) {
            const trailerFiles = trailers[0]['trailer_files'];
            if (trailerFiles != null && trailerFiles.length > 0) {
                const url = trailerFiles[0]['url'];
                return youtube_parser(url);
            }
        }
        return null;
    };

    const movieClick = () => {
        if (movie) {
            fetchUrl({
                url: '/movies/genres?lang=en',
            }).then(data => {
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'autoEvent',
                    'category': 'Website Interactions',
                    'action': 'Click on Film',
                    'film_name': movie["original_title"],	                                                                        // Film Name
                    'film_id': movie["id"],				                                                                            // Unique Film ID
                    'genre': getGenresString(data["genres"], movie["genres"]),			                                            // Film Genre
                    'production_companies': movie["production_companies"] != null ? movie["production_companies"].join() : '',      // Production Companies
                });
            });
        }
    }
</script>


<svelte:window bind:innerWidth={windowWidth}/>

<div class="flex flex-col items-start hover:scale-105 transition-transform">
    {#if windowWidth < minOptionsScreenWidth}
        <div class="relative w-full h-65vw sm:h-40vw md:h-24vw lg:h-25vw xl:h-26vw 2xl:h-27vw bg-cover bg-center bg-gruvi-poster animate-fadeIn hover:cursor-pointer">
            <a href={`/kinofilm/${movie.slug}`} on:click={movieClick}>
                <div 
                    class="w-full h-full bg-cover bg-center animate-fadeIn" 
                    style={movie.poster_image ? `background-image: url(${movie.poster_image})` : ""}
                />
            </a>
        </div>
    {:else}
        <div 
            class="relative w-full h-65vw sm:h-40vw md:h-24vw lg:h-25vw xl:h-26vw 2xl:h-27vw bg-cover bg-center bg-gruvi-poster animate-fadeIn hover:cursor-pointer"
            on:mouseover={() => hovered = true}
            on:mouseleave={() => hovered = false}
            on:focus={() => hovered = true}
            on:blur={() => hovered = false}
        >
            <div 
                class="w-full h-full bg-cover bg-center animate-fadeIn" 
                style={movie.poster_image ? `background-image: url(${movie.poster_image})` : movie.poster_image && movie.poster_image.image_files && movie.poster_image.image_files.length > 0 ? `background-image: url(${movie.poster_image.image_files[0].url})` : ""}
            >
            </div>
            {#if hovered}
                <div class="absolute top-0 w-full h-full flex flex-col justify-end bg-black bg-opacity-75">
                    <div class="relative w-full h-full flex flex-col justify-end items-center pb-5 2xl:pb-8">
                        <a class="absolute top-0 w-full h-full z-0" href={`/kinofilm/${movie.slug}`} on:click={movieClick}>
                        </a>
                        {#if getMovieUrl(movie)}    
                            <div class="w-1/3 text-white pb-5 2xl:pb-10 z-10 hover:scale-125 transition-transform" on:click={() => showPopup(movie)}>
                                <img src={play_movie_poster} alt="play-movie-poster" class="w-full" />
                            </div>
                        {/if}
                        <a class="z-10" href={`/kinofilm/${movie.slug}`}>
                            <div class="px-2.5 py-0.5 2xl:px-5 2xl:py-1 text-xs lg:text-base xl:text-xl 2xl:text-2xl 3xl:text-3xl text-white uppercase border-2 border-solid border-white hover:bg-white hover:text-black" on:click={movieClick}>Tickets kaufen</div>
                        </a>
                    </div>
                </div>
            {/if}
        </div>
    {/if}
    <div class="w-full pt-2 text-black text-xs sm:text-xl md:text-xs lg:text-base xl:text-xl 2xl:text-3xl font-bold uppercase truncate">{movie.title}</div>
    <div class="text-black text-2xs sm:text-base md:text-2xs lg:text-xs xl:text-base 2xl:text-2xl font-thin uppercase">{upcoming ? 'kommt bald' : 'jetzt im Kino'}</div>
</div>