<script>
    import { onMount } from "svelte";
    import { movies, upcomingMovies, currentMovies, currentUpcomingMovies, getMovies, isLoadingMovies, isLoadingUpcomingMovies, getUpcomingMovies, addMovies, addUpcomingMovies } from "../../stores/movie_store";
    import MoviePoster from './MoviePoster.svelte';
    import { Circle } from 'svelte-loading-spinners';
    import FaChevronDown from 'svelte-icons/fa/FaChevronDown.svelte'
    import { theme } from "../../../../config/tailwind.config.js";
    
    let windowWidth;
    const minAdsScreenWidth = Number.parseInt(theme.screens.md.slice(0, theme.screens.md.length - 2));
    const minMoviesScreenWidth = Number.parseInt(theme.screens.sm.slice(0, theme.screens.sm.length - 2));
    const mobileMoviesCount = 4;
    const desktopMoviesCount = 9;

    onMount(() => {
        getMovies(windowWidth < minMoviesScreenWidth ? mobileMoviesCount : desktopMoviesCount);
        getUpcomingMovies(windowWidth < minMoviesScreenWidth ? mobileMoviesCount : desktopMoviesCount);
    })

    const loadMoreMovies = () => {
        if (windowWidth < minMoviesScreenWidth) {
            // mobile
            addMovies(mobileMoviesCount - $currentMovies.length % mobileMoviesCount);
        } else {
            // desktop
            addMovies(desktopMoviesCount - $currentMovies.length % desktopMoviesCount);
        }
    };

    const loadMoreUpcomingMovies = () => {
        if (windowWidth < minMoviesScreenWidth) {
            // mobile
            addUpcomingMovies(mobileMoviesCount - $currentUpcomingMovies.length % mobileMoviesCount);
        } else {
            // desktop
            addUpcomingMovies(desktopMoviesCount - $currentUpcomingMovies.length % desktopMoviesCount);
        }
    };
</script>

<svelte:head>
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.0/font/bootstrap-icons.css">
</svelte:head>

<svelte:window bind:innerWidth={windowWidth}/>

<div class="w-full flex flex-row">
    {#if windowWidth >= minAdsScreenWidth}
        <div class="w-2/10"></div>
    {/if}
    <div class="w-full md:w-6/10 h-full min-h-screen pb-5 md:pb-7 lg:pb-8 flex flex-col items-center">
        <h2 class="w-full p-3 md:pt-5 md:pb-10 text-4xl sm:text-5xl text-center md:text-6xl lg:text-7xl font-bold uppercase">jetzt im Kino</h2>
        {#if $currentMovies != null && $currentMovies.length > 0 }
            <div class="w-full px-5 sm:px-14 md:px-8 lg:px-10 xl:px-12 2xl:px-14 grid gap-6 md:gap-5 lg:gap-6 xl:gap-7 2xl:gap-8 grid-cols-2 sm:grid-cols-3">
                {#each $currentMovies as movie}
                    <MoviePoster {movie} upcoming={false} />
                {/each}
            </div>
        {/if}
        
        {#if $isLoadingMovies }
            <div class="py-4">
                <Circle size="48" color="#525252" unit="px" duration="1s" />
            </div>
        {:else if $currentMovies.length == 0 }
            <div class="text-zinc-500 text-sm md:text-lg lg:text-xl xl:text-2xl">Keine Filme verfügbar</div>
        {/if}
        
        {#if $currentMovies.length > 0 && $currentMovies.length < $movies.length}    
            <div class="w-8 xl:w-12 2xl:w-16 p-2 xl:p-3 2xl:p-4 my-2 md:my-4 2xl:my-6 bg-orange hover:bg-black hover:scale-110 text-white xl:text-xl 2xl:text-3xl hover:cursor-pointer" on:click={() => loadMoreMovies()}>
                <FaChevronDown />
            </div>
        {/if}
        
        <h2 class="w-full p-3 md:pt-5 md:pb-10 text-4xl sm:text-5xl text-center md:text-6xl lg:text-7xl font-bold uppercase">kommt bald</h2>
        {#if $currentUpcomingMovies != null && $currentUpcomingMovies.length > 0 }
            <div class="w-full px-5 sm:px-14 md:px-8 lg:px-10 xl:px-12 2xl:px-14 grid gap-6 md:gap-5 lg:gap-6 xl:gap-7 2xl:gap-8 grid-cols-2 sm:grid-cols-3">
                {#each $currentUpcomingMovies as movie}
                    <MoviePoster {movie} upcoming={true} />
                {/each}
            </div>
        {/if}

        {#if $isLoadingUpcomingMovies }
            <div class="py-4">
                <Circle size="48" color="#525252" unit="px" duration="1s" />
            </div>
        {:else if $currentUpcomingMovies.length == 0 }
            <div class="text-zinc-500 text-sm md:text-lg lg:text-xl xl:text-2xl">Keine Filme verfügbar</div>
        {/if}

        {#if $currentUpcomingMovies.length > 0 && $currentUpcomingMovies.length < $upcomingMovies.length}        
            <div class="w-8 xl:w-12 2xl:w-16 p-2 xl:p-3 2xl:p-4 my-2 md:my-4 2xl:my-6 bg-orange hover:bg-black hover:scale-110 text-white xl:text-xl 2xl:text-3xl hover:cursor-pointer" on:click={() => loadMoreUpcomingMovies()}>
                <FaChevronDown />
            </div>
        {/if}
    </div>
    {#if windowWidth > minAdsScreenWidth}
        <div class="w-2/10"></div>
    {/if}
</div>
