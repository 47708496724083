<script>
  import { accordion } from '../../utils/accordion.js';
  
  let expanded = false;
</script>

<div class="w-full mb-10 sm:mb-20 px-10 sm:px-20 lg:px-30 2xl:px-40 flex flex-col overflow-hidden" use:accordion={this, expanded} itemscope itemtype="https://schema.org/CreativeWork">
  <h2 class="flex flex-row py-2 sm:py-4 lg:py-6 2xl:py-8 text-base sm:text-lg lg:text-2xl 2xl:text-3xl font-bold">
    <div class="w-3 2xl:w-4 h-auto mr-2 2xl:mr-3 bg-orange"></div>
    <span>Kinokarten online – Sehen Sie sich jetzt Ihren Lieblingsfilm in Kinos in Ihrer Nähe an!</span>
  </h2>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light">Sind Sie ein Filmfan, der nach einer kurzen Flucht mit Ihren Freunden und Ihrer Familie sucht? Aber ist der Gedanke, in einer langen Schlange zu warten, um Kinokarten zu buchen, ein Albtraum für Sie? Und oft verpasst man einen Film, auf den man so lange gewartet hat! Nur weil du das Update verpasst hast. Das ist so nervig!</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light mt-4">Mach dir keine Sorgen! gruvi MOVIES bietet Ihnen eine hervorragende Möglichkeit, neue Filmveröffentlichungen in Kinos in Ihrer Nähe zu finden. Von Neuerscheinungen bis hin zu kommenden Filmen, Sondergutscheinen und attraktiven Angeboten – wir sind die One-Stop-Plattform für eingefleischte Filmfans.</p>

  <h2 class="flex flex-row py-2 sm:py-4 lg:py-6 2xl:py-8 text-base sm:text-lg lg:text-2xl 2xl:text-3xl font-bold">
    <div class="w-3 2xl:w-4 h-auto mr-2 2xl:mr-3 bg-orange"></div>
    <span>Kinotickets bequem online buchen</span>
  </h2>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light">Immer mehr Filmliebhaber kaufen jetzt Kinokarten online. Und warum nicht! Im heutigen technologiegetriebenen digitalen Raum sind die Vorteile der Online-Buchung von Kinotickets zahlreich. Lassen Sie uns verstehen, wie gruvi MOVIES Ihnen ein bereicherndes Filmerlebnis bietet.</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light mt-4">Wir bieten Ihnen ein personalisierteres und bequemeres Erlebnis Wie oft haben Sie ein Kino besucht, um Tickets zu buchen, nur um festzustellen, dass alle Tickets ausverkauft waren? Noch ärgerlicher ist es, wenn man seinen Lieblingsplatz nicht findet und nach dem Film mit Nackenschmerzen nach Hause geht. Glücklicherweise können Sie jetzt Kinotickets kaufen – online – ohne in langen Warteschlangen warten zu müssen oder sich Gedanken darüber zu machen, ob Sie die gewünschten Plätze bekommen.</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light mt-4">Jetzt können Sie bequem von zu Hause oder von überall sitzen und Tickets online von Ihrem Mobilgerät aus buchen. Darüber hinaus können Sie frühzeitig buchen und Ihre bevorzugten Plätze erhalten, ohne das Theater besuchen zu müssen.</p>
  
  <h2 class="flex flex-row py-2 sm:py-4 lg:py-6 2xl:py-8 text-base sm:text-lg lg:text-2xl 2xl:text-3xl font-bold">
    <div class="w-3 2xl:w-4 h-auto mr-2 2xl:mr-3 bg-orange"></div>
    <span>Holen Sie sich spezielle Gutscheine</span>
  </h2>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light">Auch Ermäßigungsgutscheine ausgewählter Kinogesellschaften und Produktionshäuser können Sie bequem online erwerben.</p>
  
  <h2 class="flex flex-row py-2 sm:py-4 lg:py-6 2xl:py-8 text-base sm:text-lg lg:text-2xl 2xl:text-3xl font-bold">
    <div class="w-3 2xl:w-4 h-auto mr-2 2xl:mr-3 bg-orange"></div>
    <span>Es ist schnell und problemlos</span>
  </h2>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light">Die meisten Online-Kinoticket-Links, die Sie auf unserer Website finden, bieten eine einfache und schnelle Möglichkeit, Tickets online zu buchen. Sie benötigen nur eine gültige E-Mail-Adresse und Telefonnummer, um Kinokarten online zu kaufen. Und auf gruvi MOVIES erhalten Sie alle Neuigkeiten zu Filmstarts, bevorstehenden Veranstaltungen, Kinoprogrammen und vielem mehr – ohne Registrierung.</p>

  <h2 class="flex flex-row py-2 sm:py-4 lg:py-6 2xl:py-8 text-base sm:text-lg lg:text-2xl 2xl:text-3xl font-bold">
    <div class="w-3 2xl:w-4 h-auto mr-2 2xl:mr-3 bg-orange"></div>
    <span>Mehr Flexibilität</span>
  </h2>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light">Ein weiterer Grund, warum immer mehr Menschen Kinokarten online buchen, ist die Flexibilität. Stöbern Sie bequem in Ihren Lieblingsfilmen, die gerade im Kino laufen oder kommen, und buchen Sie Kinoticktes online – jederzeit und von überall. Es gibt keine zeitlichen Einschränkungen für die Online-Buchung von Kinotickets. Darüber hinaus bieten Ihnen einige Kinos auch die Möglichkeit, Ihre Tickets online im Voraus zu buchen. Die Sitzplatzauswahl ist ein weiteres großartiges Feature des Online-Kinokarten verkaufs.</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light mt-4">Also, worauf wartest Du? Beginnen Sie mit der Suche und finden Sie Ihre Lieblingskinos und Kinos in Ihrer Nähe.</p>

  <h2 class="flex flex-row py-2 sm:py-4 lg:py-6 2xl:py-8 text-base sm:text-lg lg:text-2xl 2xl:text-3xl font-bold">
    <div class="w-3 2xl:w-4 h-auto mr-2 2xl:mr-3 bg-orange"></div>
    <span>Wer wir sind?</span>
  </h2>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light">Wir von gruvi MOVIES sind eingefleischte Filmliebhaber! Aber die Mühen, Kinokarten zu kaufen, indem wir in langen Schlangen standen oder das neueste Film-Update verpassten, waren so deprimierend für uns. Und von da an wurde der Samen gepflanzt – der Traum, eine Plattform einzurichten, die das Anschauen von Filmen für Filmliebhaber bequemer macht.</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light mt-4">Angesichts der boomenden Kinobranche in Deutschland und der wachsenden Popularität großer Kinoketten und Multiplexe hielten wir es für die ideale Gelegenheit, Unterhaltung mit Technologie zu verbinden. Deshalb haben wir diese erstaunliche Plattform – gruvi MOVIES – ins Leben gerufen, auf der Sie Kinos in Ihrer Nähe, aktuelle Kinostarts, kommende Filme und vieles mehr finden.</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light mt-4">Wenn Sie also planen, Kinokarten für die neuesten Filme in Deutschland zu kaufen, suchen Sie nicht weiter. Unsere einfache und problemlose Online-Plattform steigert die Aufregung beim Ansehen von Filmen im Kino noch mehr. Wir zeigen Filme aus ausgewählten Kinos und helfen Ihnen, Kinotickets einfach online zu buchen und sogar Ermäßigungsgutscheine zu erhalten.</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light mt-4">Kaufen Sie Kinokarten noch heute online und erleben Sie mit Ihren Freunden und Ihrer Familie ein aufregendes und stressfreies Kinoerlebnis.</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light mt-4">Spannende deutsche und internationale Filme, für die Sie Ihre Tickets buchen können!</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light mt-4">Eine große und vielfältige Bevölkerungsbasis, von der viele Englisch sprechen, ist eine der Hauptantriebskräfte für die Popularität internationaler Filme, insbesondere Hollywoods. Tatsächlich sind einige der erfolgreichsten Filme in Deutschland Hollywood-Produktionen.</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light mt-4">Bei gruvi MOVIES bringen wir Ihnen die neuesten Updates zu den neuesten und kommenden deutschen und internationalen Filmen, die Sie einfach nicht verpassen dürfen. Sie können Ihre Lieblingsfilme nach Genres durchsuchen – Kinder, Drama, Dokumentarfilm, historisches Drama, Anime, Komödie, Horror, Thriller, Science-Fiction, Action, Romantik, Tragikomödie, Abenteuer, Musical und vieles mehr.</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light mt-4">Unsere sozial ausgerichtete und benutzerfreundliche Oberfläche ermöglicht es Filmliebhabern, sofort nach Filmen zu suchen. Während Sie bequem von zu Hause aus online sind, können Sie Kinokarten kaufen, Freunde einladen, Ermäßigungen erwerben und vieles mehr. Dies gibt Ihnen ein VIP-Erlebnis, wenn Sie im Kino sind.</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-bold mt-4">Kinotickets online buchen mit nur wenigen Klicks!</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light mt-4">Liebesfilme? Schauen Sie sich die Filme an, die in den Kinos in Ihrer Nähe laufen, buchen Sie Tickets online, gehen Sie ins Theater, schnappen Sie sich Popcorn und genießen Sie die größten Veröffentlichungen, nach denen Sie gesucht haben. So einfach ist das. Und Sie können uns nicht genug für diese unglaubliche und problemlose Erfahrung danken! Es dauert nur wenige Minuten, Kinokarten online zu buchen:</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light mt-4">1. Geben Sie in der oberen Suchleiste auf unserer Website <a href="/">https://movies.gruvi.tv/</a> einen Filmtitel ein und finden Sie einen Film oder ein Kino in Ihrer Nähe</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light mt-4">2. Wählen Sie die Filmvorführungszeit</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light mt-4">3. Sie werden zur Online-Bezahlung auf die Original-Kinokarten plattform weitergeleitet</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light mt-4">4. Geben Sie die Anzahl der Tickets ein, die Sie buchen möchten, prüfen Sie die Sitzplatzverfügbarkeit und wählen Sie die gewünschten Sitzplätze aus</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light mt-4">5. Besuchen Sie das Theater zur Vorstellungszeit und checken Sie sofort mit Ihrer Kinokarte ein</p>

  <h2 class="flex flex-row py-2 sm:py-4 lg:py-6 2xl:py-8 text-base sm:text-lg lg:text-2xl 2xl:text-3xl font-bold">
    <div class="w-3 2xl:w-4 h-auto mr-2 2xl:mr-3 bg-orange"></div>
    <span>Warum uns wählen?</span>
  </h2>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light">Wenn Sie Filme lieben und auf der Suche nach einem stressfreien und schnellen Erkundungserlebnis sind, sind wir Ihr vertrauenswürdigstes Ziel. Wir helfen Ihnen nicht nur bei der Online-Buchung von Kinokarten, sondern liefern auch wertvolle Updates zu den neusten Kinos, Filmen, Kinoprogrammen und weiteren Angeboten. Und das steht allen Nutzern ohne Registrierung zur Verfügung.</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-light mt-4">Wir sind das ultimative Ziel für Kinobesucher und bieten Filmnachrichten, Trailer, neueste Updates und eine einfache Möglichkeit, die besten Online-Kinokarten plattformen zu erreichen.</p>
  <p class="text-sm sm:text-base lg:text-xl 2xl:text-2xl font-bold mt-4">Beginnen Sie also noch heute mit dem Online-Kauf von Kinotickets.</p>
</div>
