import { get, writable } from "svelte/store";
import { coords, homeCoords } from "../stores/geolocation_store";
import { fetchUrl } from "../utils/utils";
import { formatISO, startOfDay, addDays, isToday, addHours } from 'date-fns'

const N_DATES = 31;
const defaultVersion = 'Alle';
const radius = '10';

export const allFlags = {
    '2d': '2D',
    '3d': '3D',
    imax: 'IMAX',
    ov: 'OV',
    omu: 'OMU',
    dub: 'Dubbed Version',
    sub: 'Subbed Version',
}

export const currentShowtimesCinemas = writable([]);
export const currentDay = writable({});
export const showtimesDates = writable([]);
export const isLoading = writable(false);

export const getShowtimes = (movie, day, coords) => {
    const timeFrom = formatISO(isToday(day) ? day : addHours(startOfDay(day), 8));
    const timeTo = formatISO(startOfDay(addDays(day, 1)));
    //const city_ids = '1'; // Berlin
    isLoading.set(true);
    fetchUrl({
        url: `/showtimes/api_index?movie_id=${movie["id"]}&time_from=${timeFrom}&time_to=${timeTo}&location=${coords["latitude"]},${coords["longitude"]}&distance=${radius}`,
    }).then(data => {
        const cinemaShowtimes = JSON.parse(JSON.stringify(data)).sort(compareCinemaNames);
        const cinemaShowtimesWithFlags = insertFlags(cinemaShowtimes, movie["original_language"]);
        currentShowtimesCinemas.set(cinemaShowtimesWithFlags);
        isLoading.set(false);
    })
}

export const getShowtimesByNavigator = (movie, day) => {
    window.navigator.geolocation.getCurrentPosition((data) => {
        let newCoords = {
            latitude: data.coords.latitude,
            longitude: data.coords.longitude,
        };
        coords.set(newCoords);
        homeCoords.set(newCoords);
        getShowtimes(movie, day, get(coords));
    }, (error) => {
    });
}

export const setDefaultVersion = () => {
    currentVersion.set(defaultVersion);
}

export const setVersion = value => {
    currentVersion.set(value);
}

export const setPresentDate = () => {
    setPresentDay();
}

const setPresentDay = () => {
    const today = new Date();
    setDay(today);
}

export const setDay = (thisDay) => {
    currentDay.set(thisDay);
}

const insertFlags = (cinemaShowtimes, original_language) => {
    return cinemaShowtimes.map(cinemaShowtime => {
        cinemaShowtime["showtimes"] = cinemaShowtime["showtimes"].map(showtime => {
            showtime["flags"] = getShowtimeFlags(showtime, original_language);
            return showtime;
        });
        return cinemaShowtime;
    })
}

const getShowtimeFlags = (showtime, original_language) => {
    let flags = [];
    if (showtime["is_3d"]) {
        flags.push(allFlags["3d"]);
    } else {
        flags.push(allFlags["2d"]);
    }
    if (showtime["is_imax"]) {
        flags.push(allFlags["imax"]);
    }
    if (showtime["subtitle_language"] == null) {
        if (showtime["language"] == original_language) {
            flags.push(allFlags["ov"]);
        } else {
            flags.push(allFlags["dub"]);
        }
    } else {
        if (showtime["language"] == original_language) {
            flags.push(allFlags["sub"]);
        } else {
            flags.push(allFlags["omu"]);
        }
    }
    return flags;
}

export const getShowtimesFlagList = (showtimes) => {
    let flagsList = [];
    showtimes.forEach(showtime => {
        if (!flagsList.contains(showtime["flags"])) {
            flagsList.push(showtime["flags"]);
        } 
    });
    return flagsList;
}

export const getShowtimesByFlags = (flags, showtimes) => {
    let filteredShowtimes = [];
    filteredShowtimes = showtimes.filter((showtime) => {
        return showtime["flags"].equals(flags);
    });
    return filteredShowtimes;
}

export const loadDefaultShowtimesDates = () => {
    let newShowtimesDates = [];
    for (let index = 0; index < N_DATES; index++) {
        const date = index == 0 ? startOfDay(addDays(new Date(), index)) : addDays(new Date(), index);
        newShowtimesDates.push(date);
    }
    showtimesDates.set(newShowtimesDates);
}

const compareCinemaNames = (a, b) => {
    if ( a["name"] < b["name"] ){
        return -1;
    }
    if ( a["name"] > b["name"]){
        return 1;
    }
    return 0;
}

if(Array.prototype.equals)
    console.warn("Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code.");
Array.prototype.equals = function (array) {
    if (!array)
        return false;

    if (this.length != array.length)
        return false;

    for (var i = 0, l=this.length; i < l; i++) {
        if (this[i] instanceof Array && array[i] instanceof Array) {
            if (!this[i].equals(array[i]))
                return false;       
        }           
        else if (this[i] != array[i]) { 
            return false;   
        }           
    }       
    return true;
}

if(Array.prototype.contains)
    console.warn("Overriding existing Array.prototype.contains. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code.");
// attach the .contains method to Array's prototype to call it on any array
Array.prototype.contains = function (thing) {
    // if the other array is a falsy value, return false
    if (!this)
        return false;
    
    //start by assuming the array doesn't contain the thing
    var result = false;
    for (var i = 0, l=this.length; i < l; i++) 
      {
      //if anything in the array is the thing then change our mind from before
      
      if (this[i] instanceof Array)
        {if (this[i].equals(thing))
          result = true;}
        else
          if (this[i]===thing)
            result = true;
      
    
      } 
     //return the decision we left in the variable, result
    return result;     
}
