import { get, writable } from "svelte/store";
import { fetchUrl } from "../utils/utils";

export const homeCoords = writable({
    latitude: null,
    longitude: null,
})
export const coords = writable({
    latitude: null,
    longitude: null,
});

export const getCoordinatesDefault = async () => {
    await fetchUrl({
        url: "https://app.gruvi.tv/geolocate/"
    }).then((data) => {
        let newCoords = {
            latitude: data["latitude"],
            longitude: data["longitude"],
        };
        const oldCoords = get(coords);
        if (!oldCoords.latitude && !oldCoords.longitude) {
            coords.set(newCoords);
            homeCoords.set(newCoords);
        }
    });
}

export const setCoordinates = (lat, lon) => {
    const newCoords = {
        latitude: lat,
        longitude: lon,
    }
    coords.set(newCoords);
}

export const setHomeCoordinates = (lat, lon) => {
    const newCoords = {
        latitude: lat,
        longitude: lon,
    }
    homeCoords.set(newCoords);
}