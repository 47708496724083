<script>
    import { coords, homeCoords, setCoordinates } from '../../stores/geolocation_store';
    import { currentDay, getShowtimes } from '../../stores/showtimes_store';
    import GooglePlacesAutocomplete from '../googleAutoComplete/GoogleAutoComplete.svelte';
    import IoIosHome from 'svelte-icons/io/IoIosHome.svelte';
    import { fetchUrl, getGenresString } from "../../utils/utils.js";

    export let movie;
    
    const options = {
        fields: ['address_components', 'geometry'],
        types: ['(cities)']
    };
    const placeholder = 'Suche nach Ort';
    let locationName = '';

    const searchShowtimesByLocation = (lat, lon) => {
        setCoordinates(lat, lon);
        getShowtimes(movie, $currentDay, $coords);
    }

    const getHomeShowtimes = () => {
        locationName = '';
        setCoordinates($homeCoords.latitude, $homeCoords.longitude);
        getShowtimes(movie, $currentDay, $homeCoords);
    }

    const onPlaceChanged = (event) => {
        if (event.detail) {
            const locationText = event.detail["text"];
            const city = locationText.split(',')[0];
            if (movie) {
                fetchUrl({
                    url: '/movies/genres?lang=en',
                }).then(data => {
                    window.dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        'event': 'autoEvent',
                        'category': 'Showtimes Interactions',
                        'action': 'Location Change',
                        'film_name': movie["original_title"],	                                                                        // Film Name
                        'film_id': movie["id"],				                                                                            // Unique Film ID
                        'genre': getGenresString(data["genres"], movie["genres"]),		                                                // Film Genre
                        'location': city,						                                                                        // City
                        'production_companies': movie["production_companies"] != null ? movie["production_companies"].join() : '',      // Production Companies
                    });
                });
            }
            const latitude = event.detail.place.geometry.location.lat();
            const longitude = event.detail.place.geometry.location.lng();
            searchShowtimesByLocation(latitude, longitude);
        }
    }
</script>

<div class="w-full pb-3 md:pb-6 flex flex-row justify-center items-center shadow-md">
    <div class="w-2/3 md:w-2/5 border-solid border-1 border-gray-light">
        <GooglePlacesAutocomplete 
            classes="input-selector" 
            on:place_changed={onPlaceChanged}
            {options}
            {placeholder}
            bind:value={locationName}
        />
    </div>
    <div class="ml-4 h-6 w-6 md:h-10 md:w-10 text-black hover:text-orange hover:cursor-pointer" on:click={getHomeShowtimes}>
        <IoIosHome />
    </div>
</div>
