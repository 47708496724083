export const fetchUrl = function ({url, callback, method = "GET", body, headers}) {
  return fetch(
    url,
    {
      method,
      headers: headers || {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': document.querySelector('[name=csrf-token]').content
      },
      body
    }
  ).then(response => response.json())
};

export const asyncFetch = async ({url, callback, method = "GET", body, headers}) => {
  return fetch(
    url,
    {
      method,
      headers: headers || {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': document.querySelector('[name=csrf-token]').content
      },
      body
    }
  ).then(response => response.json());
}

export const debounce = (func, wait, immediate) => {
  var timeout;
  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

export const youtube_parser = (url) => {
  const video_id = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return (video_id[2] !== undefined) ? video_id[2].split(/[^0-9a-z_\-]/i)[0] : video_id[0];
};

export const getGenresString = (genresList, genres) => {
  let newGenres = [];
  genres.forEach((genre) => {
    let index = genresList.findIndex((elem) => elem["id"] === genre["id"]);
    if (index) {
      newGenres.push(genresList[index]["name"]);
    }
  });
  return newGenres.join(', ');
}

// Adapted from here: https://stackoverflow.com/questions/10730362/get-cookie-by-name
export const getCookie = (name) => {
  let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
}

export const setCookie = (name, value) => {
  document.cookie = name + "=";
  document.cookie = name + "=" + value;
}

export const deleteCookies = () => {
  window.dataLayer.push({
    event: "gdpr",
    do_not_track: true
  });

  var cookieList = document.cookie.split("; ");

  for (var i in cookieList) {
    const cookieKey = cookieList[i].split('=')[0];
    deleteCookie(cookieKey);
  }
}

export const deleteCookie = (name) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const setGTM = () => {
  (function (w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-MK6ZGV5');
  (function (w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtag/js?id=' + i + dl; f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'UA-108295178-4');
}
