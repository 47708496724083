<script>
    import { fetchUrl, getGenresString } from "../../utils/utils.js";

    export let movie;
    export let movieId;
    export let trailerClasses = "";

    function loadVideo() {
        (function loadYoutubeIFrameApiScript() {
            const tag = document.createElement("script");
            tag.src = "https://www.youtube.com/iframe_api";

            const firstScriptTag = document.getElementsByTagName("script")[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            tag.onload = setupPlayer;
        })();

        function setupPlayer() {
            const videoRatio = 0.61;
            const videoWidth = window.innerWidth * 0.7;
            const videoHeight = videoWidth * videoRatio;
            window.YT.ready(function() {
                player = new window.YT.Player("videoTrailerPlayer", {
                        height: String(videoHeight.toFixed(2)),
                        width: String(videoWidth.toFixed(2)),
                        videoId: movieId,
                        events: {
                            onReady: onPlayerReady,
                            onStateChange: onPlayerStateChange
                        }
                });
            });
        }

        function onPlayerReady(event) {
        }

        function onPlayerStateChange(event) {
            if (movie) {
                fetchUrl({
                    url: '/movies/genres?lang=en',
                }).then(data => {
                    window.dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        'event': 'autoEvent',
                        'category': 'Video Interactions',
                        'action': 'Play',
                        'film_name': movie["original_title"],	                                                                        // Film Name
                        'film_id': movie["id"],				                                                                            // Unique Film ID
                        'genre': getGenresString(data["genres"], movie["genres"]),			                                            // Film Genre
                        'production_companies': movie["production_companies"] != null ? movie["production_companies"].join() : '',      // Production Companies
                    });
                });
            }
        }
    }

    if (document.readyState !== "loading") {
        loadVideo();
    } else {
        document.addEventListener("DOMContentLoaded", function() {
            loadVideo();
        });
    }
</script>

<div class="{trailerClasses}" id="videoTrailerPlayer"></div>
