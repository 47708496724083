<script>
    import { createEventDispatcher } from 'svelte';
    import { format, isToday, isSameDay } from 'date-fns';
    import { de } from 'date-fns/locale';
    import { currentDay } from "../../stores/showtimes_store";
    export let date;

    const dispatch = createEventDispatcher();

    const selectDay = () => {
        dispatch('selectday', {
            day: date,
        });
    }
</script>

<div 
    class="h-full px-1 md:px-2 flex justify-center items-center transition-transform hover:scale-x-110" 
    on:pointerdown={selectDay}
>
    <div class="h-full w-full flex flex-wrap justify-center items-center bg-black text-xs md:text-sm lg:text-base xl:text-lg 2xl:text-2xl text-white uppercase hover:underline font-light cursor-pointer {isSameDay(date, $currentDay) ? 'underline' : ''}">
        {#if isToday(date)}
            <span class="">Heute</span>
        {:else}
            <div class="flex flex-col sm:flex-row justify-center items-center">
                <span>{format(date, 'ccc', { locale: de })}</span>
                <span>{format(date, 'd LLL', { locale: de })}</span>
            </div>
        {/if}
    </div>
</div>