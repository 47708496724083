<script>
    import { getMovies, getUpcomingMovies, getSearchedMovies, getSearchedUpcomingMovies, searchQuery } from "../../stores/movie_store.js";
    import TextInput from "../shared/TextInput.svelte";
    import { debounce } from "../../utils/utils";
    import IoIosSearch from 'svelte-icons/io/IoIosSearch.svelte';
    import { theme } from "../../../../config/tailwind.config.js";

    let name = "name";
    let value = "";
    let placeholder = "Geben Sie einen Filmtitel ein";
    let windowWidth;

    const minMoviesScreenWidth = Number.parseInt(theme.screens.sm.slice(0, theme.screens.sm.length - 2));
    const mobileMoviesCount = 4;
    const desktopMoviesCount = 9;

    const dispatchInput = debounce(
        async (event) => {
            searchQuery.set(event.detail);
            if (event.detail != '') {
                getSearchedMovies(event.detail, windowWidth < minMoviesScreenWidth ? mobileMoviesCount : desktopMoviesCount);
                getSearchedUpcomingMovies(event.detail, windowWidth < minMoviesScreenWidth ? mobileMoviesCount : desktopMoviesCount);
            } else {
                getMovies(windowWidth < minMoviesScreenWidth ? mobileMoviesCount : desktopMoviesCount);
                getUpcomingMovies(windowWidth < minMoviesScreenWidth ? mobileMoviesCount : desktopMoviesCount);
            }
        }, 
        300
    );
</script>

<svelte:window bind:innerWidth={windowWidth}/>

<TextInput 
    {name} 
    {value} 
    {placeholder}
    classes="full"
    inputClasses="full text-sm md:text-2xl placeholder:text-black placeholder:uppercase uppercase px-1"
    icon="search"
    iconClasses="text-sm md:text-2xl text-black"
    on:input={dispatchInput}
>
    <IoIosSearch />
</TextInput>
