<script>
    import { format } from 'date-fns';
    import FaChevronDown from 'svelte-icons/fa/FaChevronDown.svelte';
    import FaChevronUp from 'svelte-icons/fa/FaChevronUp.svelte';
    import { theme } from "../../../../config/tailwind.config.js";
    import { accordion } from '../../utils/accordion.js';

    export let movie;

    let expanded = false;
    let windowWidth;
    const minInfoScreenWidth = Number.parseInt(theme.screens.md.slice(0, theme.screens.sm.length - 2));
</script>

<svelte:window bind:innerWidth={windowWidth}/>

<div 
    class="w-full {expanded ? 'h-auto' : 'h-36'} md:h-25vw 2xl:h-20vw overflow-hidden md:overflow-scroll"
    use:accordion={this, expanded}
>
    <ul>
      <li class="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-3xl mt-1 lg:mt-2 2xl:mt-3"><span class="font-bold uppercase">Beschreibung</span><p itemprop="description">{movie['synopsis']}</p></li>
      <li class="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-3xl mt-1 lg:mt-2 2xl:mt-3"><span class="font-bold uppercase">Original Title: </span><span itemprop="name">{movie['original_title']}</span></li>
      <li class="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-3xl mt-1 lg:mt-2 2xl:mt-3"><span class="font-bold uppercase">FSK: </span><span>{(movie['age_limits'] != null && movie['age_limits']['DE'] != null) ? `ab ${movie['age_limits']['DE']} Jahren` : 'N/A'}</span></li>
      <li class="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-3xl mt-1 lg:mt-2 2xl:mt-3"><span class="font-bold uppercase">Laufzeit: </span><span itemprop="duration">{movie['runtime'] ? movie['runtime'] + ' Minuten' : 'N/A'}</span></li>
      <li class="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-3xl mt-1 lg:mt-2 2xl:mt-3"><span class="font-bold uppercase">Genre: </span><span itemprop="genre">{movie['genres'] ? movie['genres'].map((g) => g['name']).join(', ') : 'N/A'}</span></li>
      <li class="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-3xl mt-1 lg:mt-2 2xl:mt-3"><span class="font-bold uppercase">Kinostart: </span><span>{movie['release_dates'] != null && movie['release_dates']['DE'] != null && movie['release_dates']['DE'][0]['date'] != null ? format(Date.parse(movie['release_dates']['DE'][0]['date']), 'dd-MM-y') : 'N/A'}</span></li>
      <li class="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-3xl mt-1 lg:mt-2 2xl:mt-3"><span class="font-bold uppercase">Cast: </span><span itemprop="actor">{movie['cast'] ? movie['cast'].slice(0, 5).map((c) => c['name']).join(', ') : 'N/A'}</span></li>
      <li class="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-3xl mt-1 lg:mt-2 2xl:mt-3"><span class="font-bold uppercase">Crew: </span><span itemprop="director">{movie['crew'] ? movie['crew'].slice(0, 5).map((c) => `${c['name']} (${c['job']})`).join(', ') : 'N/A'}</span></li>
      <li class="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-3xl mt-1 lg:mt-2 2xl:mt-3"><span class="font-bold uppercase">Production: </span><span>{movie['production_companies'] ? movie['production_companies'].slice(0, 3).join(', ') : 'N/A'}</span></li>
      <li class="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-3xl mt-1 lg:mt-2 2xl:mt-3"><span class="font-bold uppercase">Keywords: </span><span>{movie['keywords'] ? movie['keywords'].slice(0, 3).join(', ') : 'N/A'}</span></li>
    </ul>
</div>

{#if windowWidth < minInfoScreenWidth}
    <div class="flex flex-col items-center hover:cursor-pointer mt-3" on:click={() => expanded = !expanded}>
        {#if expanded}
            <span class="text-sm text-orange" >Weniger Info</span>
            <div class="w-4 text-orange">
                <FaChevronUp />
            </div>
        {:else}
            <span class="text-sm text-orange" >Mehr Info</span>
            <div class="w-4 text-orange">
                <FaChevronDown />
            </div>
        {/if}
    </div>
{/if}