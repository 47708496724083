import { writable, get } from "svelte/store";
import { fetchUrl } from "../utils/utils";
import { addDays, addMonths, subMonths, format, parse } from 'date-fns';

export const movies = writable([]);
export const upcomingMovies = writable([]);
export const currentMovies = writable([]);
export const currentUpcomingMovies = writable([]);
export const isLoadingMovies = writable(false);
export const isLoadingUpcomingMovies = writable(false);
export const searchQuery = writable('');

const country = 'DE';

export const getMovies = (n) => {
    isLoadingMovies.set(true);
    movies.set([]);
    currentMovies.set([]);
    let release_date_to = format(new Date(), 'yyyy-MM-dd');
    let release_date_from = format(subMonths(new Date(), 2), 'yyyy-MM-dd');
    fetchUrl({
        url: `/movies/current?country=${country}&release_date_from=${release_date_from}&release_date_to=${release_date_to}`,
    }).then(data => {
        if (data['movies'] && data['movies'].length != 0) {
            const filteredMovies = filterMovies(data['movies']);
            const sortedMovies = filteredMovies.sort(sortMoviesByReleaseDateAndTitle).reverse();
            if (get(searchQuery) === '') {
                movies.set(sortedMovies);
                addMovies(n);
            }
        }
        if (get(searchQuery) === '') {
            isLoadingMovies.set(false);
        }
    });
}

export const getUpcomingMovies = (n) => {
    isLoadingUpcomingMovies.set(true);
    upcomingMovies.set([]);
    currentUpcomingMovies.set([]);
    let release_date_from = format(addDays(new Date(), 1), 'yyyy-MM-dd');
    let release_date_to = format(addMonths(addDays(new Date, 1), 6) , 'yyyy-MM-dd');
    fetchUrl({
        url: `/movies/upcoming?country=${country}&release_date_from=${release_date_from}&release_date_to=${release_date_to}`,
    }).then(data => {
        if (data['movies'] && data['movies'].length != 0) {
            const filteredMovies = filterMovies(data['movies']);
            const sortedMovies = filteredMovies.sort(sortMoviesByReleaseDateAndTitle);
            if (get(searchQuery) === '') {
                upcomingMovies.set(sortedMovies);
                addUpcomingMovies(n);   
            }
        }
        if (get(searchQuery) === '') {
            isLoadingUpcomingMovies.set(false);
        }
    });
}

export const getSearchedMovies = (queryString, n) => {
    isLoadingMovies.set(true);
    movies.set([]);
    currentMovies.set([]);
    let release_date_to = format(new Date(), 'yyyy-MM-dd');
    fetchUrl({
        url: `/movies/current/search?search_query=${queryString}&country=${country}&release_date_to=${release_date_to}`,
    }).then(data => {
        if (data['movies'] && data['movies'].length != 0) {
            const filteredMovies = filterMovies(data['movies']);
            const sortedMovies = filteredMovies.sort(sortMoviesByReleaseDateAndTitle).reverse();
            if (get(searchQuery) === queryString) {
                movies.set(sortedMovies);
                addMovies(n);
            }
        }
        if (get(searchQuery) === queryString) {
            isLoadingMovies.set(false);
        }
    });
}

export const getSearchedUpcomingMovies = (queryString, n) => {
    isLoadingUpcomingMovies.set(true);
    upcomingMovies.set([]);
    currentUpcomingMovies.set([]);
    let release_date_from = format(addDays(new Date(), 1), 'yyyy-MM-dd');
    fetchUrl({
        url: `/movies/upcoming/search?search_query=${queryString}&country=${country}&release_date_from=${release_date_from}`,
    }).then(data => {
        if (data['movies'] && data['movies'].length != 0) {
            const filteredMovies = filterMovies(data['movies']);
            const sortedMovies = filteredMovies.sort(sortMoviesByReleaseDateAndTitle);
            if (get(searchQuery) === queryString) { 
                upcomingMovies.set(sortedMovies);
                addUpcomingMovies(n);
            }
        }
        if (get(searchQuery) === queryString) {
            isLoadingUpcomingMovies.set(false);
        }
    })
}

export const addMovies = (n) => {
    const newCurrentMovies = get(currentMovies);
    const thisMovies = get(movies);
    const currentLength = newCurrentMovies.length;
    let i = 0;
    while (newCurrentMovies.length < thisMovies.length && i < n) {
        newCurrentMovies.push(thisMovies[currentLength + i]);
        i++;
    }
    currentMovies.set(newCurrentMovies);
}

export const addUpcomingMovies = (n) => {
    const newCurrentUpcomingMovies = get(currentUpcomingMovies);
    const thisUpcomingMovies = get(upcomingMovies);
    const currentLength = newCurrentUpcomingMovies.length;
    let i = 0;
    while (newCurrentUpcomingMovies.length < thisUpcomingMovies.length && i < n) {
        newCurrentUpcomingMovies.push(thisUpcomingMovies[currentLength + i]);
        i++;
    }
    currentUpcomingMovies.set(newCurrentUpcomingMovies);
}

const sortMoviesByReleaseDateAndTitle = (movie1, movie2) => {
    const movieReleaseDate1 = parse(movie1['release_dates']['DE'][0]['date'], 'yyyy-MM-dd', new Date());
    const movieReleaseDate2 = parse(movie2['release_dates']['DE'][0]['date'], 'yyyy-MM-dd', new Date());
    if (movieReleaseDate1 - movieReleaseDate2 > 0) {
        return 1;
    } else if (movieReleaseDate1 - movieReleaseDate2 < 0) {
        return -1;
    } else {
        return ('' + movie1['title']).localeCompare(movie2['title']);
    }
}

const filterMovies = (movies) => {
    return movies.filter((movie) => {
        return movie['release_dates'] != null && movie['release_dates']['DE'] != null && movie['release_dates']['DE'].length > 0 && movie['release_dates']['DE'][0]['date'] != null;
    })
}