<script>
    import { onMount } from "svelte";
    import ShowtimesCalendarDate from "./ShowtimesCalendarDate.svelte";
    import FaChevronLeft from 'svelte-icons/fa/FaChevronLeft.svelte';
    import FaChevronRight from 'svelte-icons/fa/FaChevronRight.svelte';
    import { fetchUrl, getGenresString } from "../../utils/utils.js";
    import { currentDay, showtimesDates, getShowtimes, getShowtimesByNavigator, setPresentDate, setDay } from "../../stores/showtimes_store";
    import { loadDefaultShowtimesDates } from "../../stores/showtimes_store";
    import { coords, getCoordinatesDefault } from "../../stores/geolocation_store";
    import { theme } from "../../../../config/tailwind.config.js";
    import { format } from 'date-fns';
    import { de } from 'date-fns/locale';
    import Carousel from 'svelte-carousel';

    export let movie;

    let windowWidth;
    const minScreenWidth = Number.parseInt(theme.screens.sm.slice(0, theme.screens.sm.length - 2));

    onMount(() => {
        loadDefaultShowtimesDates();
        setPresentDate();
        loadCoordsAndShowtimes();

        const el = document.getElementById('carousel-wrapper');
        if (el) {
            el.children[0].children[0].style.height = windowWidth < minScreenWidth ? '3rem' : '5rem';
        }
    })

    const loadCoordsAndShowtimes = async () => {
        await getCoordinatesDefault();
        getShowtimes(movie, $currentDay, $coords);
        getShowtimesByNavigator(movie, $currentDay);
    }

    function loadShowtimes(event) {
        if (movie) {
            fetchUrl({
                url: '/movies/genres?lang=en',
            }).then(data => {
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'autoEvent',
                    'category': 'Showtimes Interactions',
                    'action': 'Date Change',
                    'film_name': movie["original_title"],	                                                                        // Film Name
                    'film_id': movie["id"],				                                                                            // Unique Film ID
                    'genre': getGenresString(data["genres"], movie["genres"]),			                                            // Film Genre
                    'showtime_time': format(event.detail.day, 'H:m', { locale: de }),			                                    // Showtime Time
                    'showtime_date': format(event.detail.day, 'yyyy-MM-dd', { locale: de }),	                                    // Showtime Date
                    'production_companies': movie["production_companies"] != null ? movie["production_companies"].join() : '',      // Production Companies
                });
            });
        }
        setDay(event.detail.day);
        getShowtimes(movie, $currentDay, $coords);
    }
</script>

<svelte:window bind:innerWidth={windowWidth}/>

<div id="carousel-wrapper" class="w-full h-12 md:h-20 px-4 md:px-16 xl:px-32 2xl:px-64 mb-4 sm:mb-10 flex justify-center">
    <Carousel 
        let:showPrevPage
        let:showNextPage
        infinite={false}
        swiping={false}
        dots={false}
        particlesToShow={windowWidth < minScreenWidth ? 3 : 7}
        particlesToScroll={windowWidth < minScreenWidth ? 3 : 7}
    >
        <div slot="prev" class="flex bg-orange text-white w-11 md:w-14 xl:w-16 h-full mr-1 md:mr-2 px-3 py-2 md:px-3 md:py-2 cursor-pointer" on:click={showPrevPage}>
            <FaChevronLeft />
        </div>
        {#each $showtimesDates as day}
            <ShowtimesCalendarDate date={day} on:selectday={loadShowtimes} />
        {/each}
        <div slot="next" class="flex bg-orange text-white w-11 md:w-14 xl:w-16 h-full ml-1 md:ml-2 px-3 py-2 md:px-3 md:py-2 cursor-pointer" on:click={showNextPage}>
            <FaChevronRight />
        </div>
    </Carousel>
</div>
