<script>
    import { createEventDispatcher } from 'svelte';
    import { allFlags, getShowtimesByFlags, getShowtimesFlagList } from "../../stores/showtimes_store";
    import { parseISO, format } from "date-fns";

    export let showtimes;

    let flagsList = getShowtimesFlagList(showtimes);

    const dispatch = createEventDispatcher();

    const showtimeExit = (showtime) => {
        dispatch('showtimeExit', {
            showtime,
        })
    }
</script>

<div class="w-full flex flex-col">
    {#each flagsList as flags}
        <div class="flex flex-row flex-wrap mb-2">
            {#each flags as flag}
                <div 
                    class="px-1 py-0.5 mr-1.5 border-1 border-orange text-sm md:text-lg 2xl:text-xl
                    {flag == allFlags['sub'] || flag == allFlags['dub'] ? "font-thin" : "font-bold uppercase"}"
                >
                    {flag == allFlags['dub'] ? 'Dubbed Version' : flag == allFlags['sub'] ? 'Subbed Version' : flag}
                </div>
            {/each}
        </div>
        <div class="w-full flex flex-row flex-wrap mb-1.5">
            {#each getShowtimesByFlags(flags, showtimes) as showtime}
                <a class="mr-1.5 mb-1.5" href={showtime["booking_link"]}>
                    <div class="transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-100 w-22 md:w-28 2xl:w-32 bg-orange hover:bg-black px-3 py-1.5 text-sm text-center md:text-lg 2xl:text-xl text-white uppercase" on:click={() => showtimeExit(showtime)}>{format(parseISO(showtime["start_at"]), "hh:mm aaa")}</div>
                </a>
            {/each}
        </div>
    {/each}
</div>
